<template>
    <div id="knowledge-base-article">
      <b-row>
        <b-col lg="12" md="12" order="1" order-md="2">
          <b-card class="p-5">
            <!-- Title -->
            <b-row>
              <!-- Categories Section -->
              <b-col md="2">
                <b-card-title class="mb-1">
                  <b-row>
                    <b-col md="1">
                      <div>
                        <img src="@/assets/images/pages/prepworld.svg" height="150px" width="150px" />
                      </div>
                    </b-col>
                  </b-row>
                </b-card-title>
                <div class="mt-1" v-for="(item, i) in items" :key="i">
                  <span @click="changeItem(i)" style="text-decoration: underline; cursor: pointer;"> {{ item.title }} </span>
                </div>
              </b-col>
  
              <!-- Questions and Video Display -->
              <b-col md="10">
                <div v-if="items.length > 0">
                  <b-card>
                    <b-card-title>{{ items[activeIndex].title }}</b-card-title>
                    <div
                      v-if="
                        items[activeIndex] &&
                        items[activeIndex].questions &&
                        items[activeIndex].questions.length > 0
                      "
                    >
                      <b-list-group>
                        <b-list-group-item
                          v-for="(question, i) in items[activeIndex].questions"
                          :key="`${activeIndex}-${i}`">
                          <b-button
                            v-b-toggle="'collapse-' + activeIndex + '-' + i" 
                            variant="link"
                            class="text-left w-100"
                          >
                            <feather-icon
                              icon="ChevronDownIcon"
                              class="ml-2"
                              size="16"
                            />
                            {{ question.title }}
                          </b-button>

                          <!-- Collapsible Content for Answer -->
                          <b-collapse :id="'collapse-' + activeIndex + '-' + i" class="mt-2">
                            <p>{{ question.answer }}</p>

                            <!-- Video Display -->
                            <div v-if="question.videoLink">
                              <div v-if="question.is_youtube === 1">
                                <iframe
                                  :src="getEmbedUrl(question.videoLink)"
                                  class="w-100"
                                  height="315"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen
                                ></iframe>
                              </div>
                              <div v-else>
                                <video id="video-preview" width="100%" height="auto" controls>
                                  <source :src="question.videoLink" type="video/mp4" />
                                </video>
                              </div>
                            </div>
                          </b-collapse>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                    <div v-else>
                      <b-alert show variant="info">No questions found</b-alert>
                    </div>
                  </b-card>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
           
      </b-row>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BAlert,
    BCollapse,
  } from 'bootstrap-vue';
  import axiosIns from "@/libs/axios";
  
  
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BListGroup,
      BListGroupItem,
      BCardTitle,
      BAlert,
      BCollapse,
    },
    data() {
      return {
        activeIndex: 0,
        items: [],
        collapsedIndex: -1, // Keeps track of which question is currently expanded
  
      };
    },
    methods: {
      // Fetch FAQ list from API
      fetchFaqList() {
        axiosIns
          .post(`faqGroupListClient/79`)
          .then((res) => {
            this.items = res.data;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      },
      // Handle question category change
      changeItem(index) {
        this.activeIndex = index;
      },
      // Extract YouTube embed URL
      getEmbedUrl(url) {
        if (!url) return '';
        const videoId = url.split('v=')[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
    },
    created() {
      this.fetchFaqList();
    }
  };
  </script>
  
  <style>
  #video-container {
    max-width: 1000px;
    margin: auto;
    text-align: center;
  }
  
  #transcript-container {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .transcript-line {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }
  
  .transcript-line:hover .time-marker {
    text-decoration: underline;
  }
  
  .time-marker {
    margin-right: 10px;
    color: gray;
  }
  
  .highlighted {
    background-color: #f9f001;
  }
  </style>